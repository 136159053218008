<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/health-coaching/feedback-img.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span style="font-size: 18px; color: red; font-weight: 500;">"WE CARE (Y)OUR CHILDREN"</span>
                    <p style="font-size: 17px; text-align: justify;">The school is not merely a building nor is it just a place where people come and go for 
                        various purposes. At St.Michael, we believe that it is a HOME, where life is nurtured and 
                        flourished, a house where pupils enjoy the life which is full of joy, choices and great 
                        opportunities for their growth and development – physical, intellectual, emotional and 
                        artistic. <br>
                        We believe that a blend of homely atmosphere and competitive environment is the best 
                        setting for the pupils to develop their existing potentials to the maximum. Our educational 
                        programmes and developmental strategies are modified to suit the National Education 
                        Policy and the changing world of competitiveness. 
                        Apart from the traditional subjects, our programme includes the essentials of artificial 
                        intelligence and coding right from the foundational years. Hence St.Michael is a place 
                        where each child will be enabled to identify his or her unique qualities, to realise the true 
                        potential, and to fulfill the aspirations that undergo constructive changes. We welcome 
                        the young ones of all nationalities to make their best choice by entering our school 
                        campus.</p>
                    <div class="client-info">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="title">
                                <h3> Mr.Mathivadanan.K</h3>
                                <span>Principal</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>