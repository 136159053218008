<div 
    class="training-course-banner-area"
    [class.dark-training-course-banner-area]="themeService.isDark()"
>
    <div class="container-fluid" >
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="training-course-banner-content">
                    <h1>Creating  <span>Leaders</span> of Tomorrow</h1>
                                        <div class="btn-box">
                        
                    </div>
                    <div class="trusted-by-content">
                        <span class="title">Trusted by:</span>
                        <div class="partner-slides">
                            <owl-carousel-o [options]="partnerSlides">
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner7.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner8.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner9.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner10.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner11.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="single-partner-item">
                                        <a href="#" class="d-block" target="_blank">
                                            <img src="assets/img/partner/partner12.png" alt="image">
                                        </a>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="training-course-banner-image">
                    <img src="assets/img/motivation-course/training-course-banner.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>