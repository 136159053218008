<app-header-style-five></app-header-style-five>

<app-homenine-main-banner></app-homenine-main-banner>

<app-features-style-two></app-features-style-two>

<app-health-experience></app-health-experience>

<div class="container">
    <div class="section-title text-start">
        <br><br>
        <span class="playfair-display " style="text-align: center;" ><h1 style="color: red; text-shadow: 1px, 4px, 4px black;">OUR MANAGEMENT TEAM</h1> </span>
      
       
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-team-card mb-30">
                <img src="assets/img/motivation-course/team/team-1.jpg" alt="image">
                <div class="team-content">
                    <h3>Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA)</h3>
                    <span>Founder </span>
           
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-team-card mb-30">
                <img src="assets/img/motivation-course/team/team-2.jpg" alt="image">
                <div class="team-content">
                    <h3>Ln. Dr. M.Stalin Arockiaraj, B.E., M.S.,</h3>
                    <span>CHAIRMAN</span>
               
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-team-card mb-30">
                <img src="assets/img/motivation-course/team/team-3.jpg" alt="image">
                <div class="team-content">
                    <h3>Dr. S.Bridget Nirmala, M.E., Ph.D.,</h3>
                    <span>CEO</span>
                   
                </div>
            </div>
        </div>
    </div>
</div>



<app-health-quote></app-health-quote>



<app-homenine-blog></app-homenine-blog>
