<!-- Header -->
<header class="header-area p-relative">
    <!-- Top Header -->
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call: 
                            <a href="tel:+502464674"> 99427 96933</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
               <!-- <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                    </ul>
                </div>-->
            </div>
        </div>
    </div>
    <!-- Navbar -->
    <div
        class="navbar-area navbar-style-three"
        [ngClass]="{'sticky': isSticky}"
        [class.dark-navbar-area]="themeService.isDark()"
    >
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a
                    class="navbar-brand"
                    routerLink="/"
                    [class.d-none]="themeService.isDark()"
                >
                    <img src="assets/img/black-logo.png" alt="logo">
                </a>
                <a
                    class="navbar-brand d-none"
                    routerLink="/"
                    [class.d-block]="themeService.isDark()"
                >
                    <img src="assets/img/logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                       
                      
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Admission <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                            
                          
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Admission Procedures</a></li>
                             
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Download Application</a></li>
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rules & Regulation</a></li>
                                
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Academics <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                            
                          
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Curriculum</a></li>
                             
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Teaching Methodology</a></li>
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">10th & 12th Results</a></li>
                                
                            </ul>
                        </li>-->
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Admission</a></li>
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Academics</a></li>
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                       
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                               
                                
                            </a>
                            
                            
                           
                           
                        </div>
                    </div>
                 
                    <div class="search-box d-inline-block">
                        <img src="assets/img/logoappa.png" class="shadow-sm" alt="flag">
                    </div>
                </div>
           
            </nav>
        </div>
    </div>

</header>

