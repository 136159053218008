<div class="hero-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <!--<div class="hero-banner-content">
                            <span class="sub-title"><h1>CREATING</h1></span>
                            <span ><h1 style="color: red;">LEADERS</h1></span>
                            <span><h1>OF TOMORROW</h1></span>
                          
                        </div>-->
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img1.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>