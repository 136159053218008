<section
    class="blog-area bg-image ptb-100"
    [class.dark-blog-area]="themeService.isDark()"
>
    <div class="container">
     
        <div class="row ">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a  class="category">Music School</a>
                       
                    
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/" class="category">Sports</a>
                    
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/" class="category">Library</a>
                       
                
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/" class="category">Class Room</a>
                       
                
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/" class="category">Transport</a>
                       
                
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="assets/img/health-coaching/blog-img6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/" class="category">Innovation Lab</a>
                       
                
                    </div>
                </div>
            </div>
  
        </div>
    </div>
    
</section>
