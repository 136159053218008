<section
    class="services-area pt-100 pb-70"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            
            <h2 class="sub-title"><span style="text-shadow: 1px 4px 4px black;">Salient Features</span></h2>
            
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                   
                    <h3 class="playfair-display">Hi Tech Multimedia 
                        Computer Lab </h3>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                 
                    <h3 class="playfair-display"> Modernized 
                        Science Laboratory</h3>
                   
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                
                    <h3 class="playfair-display">  Robotics & 
                        3D Printing 
                        Lab</h3>
                   
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                  
                    <h3 class="playfair-display">Air Conditioned
                        Conference Hall</h3>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                   
                    <h3 class="playfair-display"> RO Plant 
                        Drinking Water </h3>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    
                    <h3 class="playfair-display">Modernized
                        Library </h3>
                    
                </div>
            </div>
      
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                   
                    <h3 class="playfair-display">  Connected 
                        Transport Facilities </h3>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                   
                    <h3 class="playfair-display">  Furnished 
                        Awesome Auditorium </h3>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                   
                    <h3 class="playfair-display"> Indoor Shuttle 
                        Sports Complexs </h3>
                    
                </div>
            </div>
      
        </div>
    </div>
    <div class="health-coaching-shape1"><img src="assets/img/health-coaching/health-coaching-shape1.png" alt="image"></div>
    <div class="health-coaching-shape2"><img src="assets/img/health-coaching/health-coaching-shape2.png" alt="image"></div>
</section>