<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">9, Michael Gardens, 
                                <br> P&T Nagar Extn.
                               
                                <br> Madurai - 625 017.
                                <br>Tamil Nadu.
                                <br>India</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="">+91 99427 96933</a><br>
                            <a href="">+91 98426 19197</a>
                        </li>
                        <li>
                           
                            
                            
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/FatimaMichaelEngineeringCollegeMadurai" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                       
                        <li><a href="https://www.instagram.com/fmcet_mdu/?igshid=MzNlNGNkZWQ4Mg%3D%3D" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                       
                       
                    </ul>
                </div>
            </div>
         
      
            <div class="col-lg-10 col-md-6 col-sm-6">
          
                  <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.52135368592!2d78.1229052751349!3d9.97372229013032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c616d35169df%3A0xce19c2968340264b!2sSt.Michael%20Matriculation%20Hr.Sec.School%20Madurai!5e0!3m2!1sen!2sin!4v1721906625052!5m2!1sen!2sin" width="1000" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
             
            </div>
    

        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p>© St.Michael Matric Hr. Sec. School, Madurai <a href="https://http://stmichaelschoolmadurai.com/" target="_blank">| All rights reserverd</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>