<div class="health-coaching-bg-image">

    <!-- Main Banner Area -->
    <section
        class="health-coaching-banner"
        [class.dark-health-coaching-banner]="themeService.isDark()"
    >
        <div class="container-fluid">
            <div class="health-coaching-banner-slides">
                <owl-carousel-o [options]="healthCoachingBannerSlides">
                    <ng-template carouselSlide>
                        <div class="single-banner-item bg-1">
                            <h1 class="playfair-display" >Creating <br>LEADERS<br> of Tomorrow</h1>
                           
                            <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join with Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-banner-item bg-2">
                            <h1 class="playfair-display" >Creating <br>LEADERS<br> of Tomorrow</h1>
                            
                            <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join with Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </section>
           
            <br><br><br><br><br><br>   <br><br><br><br><br><br>   <br><br><br><br><br><br> <br><br><br><br><br><br> 
    <!-- Featured Area -->
    <section
        class="featured-area pt-100 pb-70"
        [class.dark-featured-area]="themeService.isDark()"
    >
      <!---->  <div class="container">
            <div class="section-title">
            
            
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-6 col-sm-12">
               
                </div>
              
            
            </div>
        </div>
    </section>

</div>

