<div
    class="education-course-banner-area"
    [class.dark-education-course-banner-area]="themeService.isDark()"
>
  <div class="container">
        <!--  <div class="education-course-banner-content">
            <h1>A Course Is Essential For Building A Career</h1>
            <div class="btn-box">
                <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
            </div>
        </div>-->
    </div>
   