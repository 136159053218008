<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <span class="sub-title">St.Michael's</span>
                    <h2 class="playfair-display">Methodology of Learning</h2>
                   
                    <ul class="features-list">
                        <li><span><i class="bx bx-check"></i>  Play/activity-Based Learning</span></li>
                        <li><span><i class="bx bx-check"></i>  Discovery Learning</span></li>
                        <li><span><i class="bx bx-check"></i> Problem-Solving</span></li>
                        <li><span><i class="bx bx-check"></i>  Increased Formal Interactive Learning Styles</span></li>
                        <li><span><i class="bx bx-check"></i>  Outcome-based Listening, Speaking, Reading 
                            & Writing</span></li>
                        <li><span><i class="bx bx-check"></i>  Contextualised Numerical Skills</span></li>
                        <li><span><i class="bx bx-check"></i> Problem-Solving</span></li>
                        <li><span><i class="bx bx-check"></i>    Interpersonal Learning</span></li>
                        <li><span><i class="bx bx-check"></i>   Collaborative Learning</span></li>
                        <li><span><i class="bx bx-check"></i>   Reciprocal Learning</span></li>
                    
                    
                    
                    </ul>


                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join with Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="experience-image">
                    <img src="assets/img/health-coaching/experience-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    
</section>
